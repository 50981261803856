import React, { useEffect } from 'react';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import './HeroSlideup.scss';

function HeroSlideup({
    header,
    additionalClasses = [],
    image,
}) {
    gsap.registerPlugin(ScrollTrigger);
    useEffect(() => {
        ScrollTrigger.create({
            trigger: '#section-start',
            start: 'top top',
            endTrigger: '#section-end',
            end: 'top top',
            pin: '#hero-slideup-section',
            pinSpacing: false,
            scrub: true,
        });
    }, []);
    return (
        <>
            <div id="section-start" />
            <section className={['hero-slideup-section', ...additionalClasses].join(' ')} id="hero-slideup-section">
                <h1 className="hero-slideup-section__header">{header}</h1>
            </section>
            <div id="section-end" />
            <section
                className={['hero-slideup-section', 'hero-slideup-section__image', ...additionalClasses].join(' ')}
                style={{ backgroundImage: `url('${image}')` }}
            />
        </>
    );
}

export default HeroSlideup;
